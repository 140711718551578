/* eslint-disable */
import { i18n } from '@/locales/i18n';
import { GenericModel } from '@/shared/model/generic-model';
import IdField from '@/shared/fields/id-field';
import StringField from '@/shared/fields/string-field';
import DateTimeField from '@/shared/fields/date-time-field';
import JsonField from '@/shared/fields/json-field';
import IntegerField from '@/shared/fields/integer-field';
// import StringArrayField from '@/shared/fields/string-array-field';
// import BooleanField from '@/shared/fields/boolean-field';
// import IntegerField from '@/shared/fields/integer-field';
// import ImagesField from '@/shared/fields/images-field';
// import DateTimeRangeField from '@/shared/fields/date-time-range-field';
// import GenericField from '@/shared/fields/generic-field';

function label(name) {
  return i18n(`menu.fields.${name}`);
}

const fields = {
  id: new IdField('id', label('id')),
  name: new JsonField('name', label('name'), ['en', 'ar']),
  file: new StringField('file', label('file')),
  accountId: new StringField('accountId', label('accountId')),
  menuNumber: new IntegerField('menuNumber', label('menuNumber')),

  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  createdBy: new StringField('createdBy', label('createdBy')),
  updatedBy: new StringField('updatedBy', label('updatedBy')),
  // createdAtRange: new DateTimeRangeField('createdAtRange', label('createdAtRange')),
};

export class MenuModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
