<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box p-5 dark:bg-theme-dark-2">
          <h2 class="text-xl font-medium mr-auto border-b pb-5">
            <I18n code="menuPage.edit.title" v-if="isUpdating"></I18n>
            <I18n code="menuPage.new.title" v-else></I18n>
          </h2>
          <section class="relative">
            <div
              class="flex justify-center"
              style="width: 100%; height: 60vh"
              v-if="loading"
            >
              <LoadingIcon icon="grid" color="#2D3748" style="width: 100px" />
            </div>
            <div>
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="
                    text-lg
                    font-medium
                    leading-none
                    items-center
                    mr-2
                    lg:col-span-2
                    col-span-12
                  "
                >
                  <I18n code="menuPage.fields.nameInEnglish"></I18n>
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <input
                    type="text"
                    class="
                      form-control
                      w-full
                      dark:bg-dark-9 dark:placeholder-white
                    "
                    required
                    v-model="model.name.en"
                    :placeholder="i18n('menuPage.fields.nameInEnglish')"
                  />
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="
                    text-lg
                    font-medium
                    leading-none
                    items-center
                    mr-2
                    lg:col-span-2
                    col-span-12
                  "
                >
                  <I18n code="menuPage.fields.nameInArabic"></I18n>
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <input
                    type="text"
                    class="
                      form-control
                      w-full
                      dark:bg-dark-9 dark:placeholder-white
                    "
                    required
                    v-model="model.name.ar"
                    :placeholder="i18n('menuPage.fields.nameInArabic')"
                  />
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="
                    text-lg
                    font-medium
                    leading-none
                    items-center
                    mr-2
                    lg:col-span-2
                    col-span-12
                  "
                >
                  <I18n code="menuPage.fields.file"></I18n>
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <div class="lg:col-span-6 col-span-12">
                    <Dropzone
                      ref-key="dropzoneSingleRef"
                      id="dropzoneSingleRef"
                      :options="{
                        url: 'https://httpbin.org/post',
                        paramName: 'file',
                        // thumbnailWidth: 150,
                        // maxFilesize: 0.5,
                        // clickable: !isMaxUploaded,
                        addRemoveLinks: true,
                        maxFiles: 1,
                        headers: { 'My-Awesome-Header': 'header value' }
                      }"
                      class="dropzone mt-6"
                    >
                      <div class="text-lg font-medium">
                        {{ i18n('menuPage.fileUploaderHint') }}
                      </div>
                    </Dropzone>
                    <!-- <div v-else class="flex relative">
                      <pdf src="https://pdftron.s3.amazonaws.com/downloads/pl/demo-annotated.pdf"></pdf>
                      <img src="/images/deleteIcon.png" alt="" class="delete-icon w-12 h-12 absolute-bottom-4-right-4" @click="this.model.file = ''" />
                    </div> -->
                  </div>
                </div>
              </div>
              <div class="flex justify-center items-center mt-10">
                <AppButton
                  type="button"
                  class="btn bg-theme-31 text-white cursor-pointer"
                  :class="!saveLoading ? 'w-24' : ''"
                  :disabled="saveLoading || findLoading"
                  :loading="saveLoading"
                  @click="doSubmit()"
                >
                  <strong>{{ i18n('common.save') }}</strong>
                  <template v-slot:loading>
                    <span>{{ i18n('common.loading') }}</span>
                    <LoadingIcon
                      icon="three-dots"
                      color="#FFFFFF"
                      style="margin: 0 4px"
                    />
                  </template>
                </AppButton>
                <button
                  type="button"
                  class="
                    btn
                    bg-theme-32
                    text-black
                    w-24
                    ml-3
                    mr-3
                    cursor-pointer
                  "
                  @click="doCancel()"
                >
                  {{ i18n('common.cancel') }}
                </button>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, provide } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import Dropzone from 'dropzone'
import Message from '@/shared/message/toastify'
// import pdf from 'vue3-pdf'
import { FormSchema } from '@/shared/form/form-schema'
import { MenuModel } from '@/store/menu/menu-model'

const { fields } = MenuModel
const formSchema = new FormSchema([
  fields.id,
  fields.name,
  fields.file,
  fields.file,
  fields.menuNumber,
  fields.accountId
])

export default defineComponent({
  props: {
    id: {
      type: String,
      required: false
    }
  },
  setup() {
    let uploadLoading = ref(false)
    const dropzoneSingleRef = ref()
    const filesToSave = ref([])
    provide('bind[dropzoneSingleRef]', (el) => {
      console.log('el =', el)
      dropzoneSingleRef.value = el
    })
    const clearDropzone = () => {
      Dropzone.forElement('#dropzoneSingleRef').removeAllFiles(true)
      while (filesToSave.value.length > 0) {
        filesToSave.value.pop()
      }
    }
    const getDropZoneInstance = () => {
      return dropzoneSingleRef.value
    }
    const setupDropZone = () => {
      const elDropzoneSingleRef = getDropZoneInstance()
      elDropzoneSingleRef.dropzone.on('success', (file) => {
        filesToSave.value.push(file)
      })

      elDropzoneSingleRef.dropzone.on('removedfile', (file) => {
        filesToSave.value.pop()
      })

      elDropzoneSingleRef.dropzone.on('complete', () => {
        uploadLoading = false
      })

      elDropzoneSingleRef.dropzone.on('uploadprogress', () => {
        uploadLoading = true
      })
      // elDropzoneSingleRef.dropzone.on('error', () => {
      //   const errorMessage = $i18n.locale == 'ar' ? 'لا مزيد من الملفات' : 'No more files please!'
      //   alert(errorMessage)
      // })
    }
    onMounted(() => {
      setupDropZone()
    })
    return {
      setupDropZone,
      uploadLoading,
      filesToSave,
      getDropZoneInstance,
      clearDropzone
    }
  },
  data() {
    return {
      model: {
        name: {
          en: null,
          ar: null
        }
      },
      rules: formSchema.rules(),
      errorMessage: null
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      currentUser: 'auth/currentUser',
      record: 'menu/form/record',
      findLoading: 'menu/form/findLoading',
      saveLoading: 'menu/form/saveLoading'
    }),
    isEditing() {
      return !!this.id
    }
    // isMaxUploaded() {
    //   return this.filesToSave.length == 1
    // }
  },
  created() {
    document.title = this.i18n('routes.app') + ' | ' + this.i18n('routes.menu')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.menu')
    }
  },
  async mounted() {
    if (this.isEditing) {
      await this.doFind(this.id)
    } else {
      await this.doNew()
    }

    this.model = formSchema.initialValues(this.record || {})
    if (this.isEditing) {
      this.model.name = {
        en: this.record.en,
        ar: this.record.ar
      }
    }
  },
  methods: {
    ...mapActions({
      doNew: 'menu/form/doNew',
      doFind: 'menu/form/doFind',
      doCreate: 'menu/form/doCreate',
      doUpdate: 'menu/form/doUpdate'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    // setupDropZone() {
    //   const elDropzoneSingleRef = this.getDropZoneInstance()
    //   console.log(elDropzoneSingleRef)
    //   // debugger
    //   elDropzoneSingleRef.dropzone.on('success', file => {
    //     this.filesToSave.push(file)
    //   })

    //   elDropzoneSingleRef.dropzone.on('complete', () => {
    //     this.uploadLoading = false
    //   })

    //   elDropzoneSingleRef.dropzone.on('uploadprogress', () => {
    //     this.uploadLoading = true
    //   })

    //   elDropzoneSingleRef.dropzone.on('error', () => {
    //     const errorMessage = this.$i18n.locale == 'ar' ? 'لا مزيد من الملفات' : 'No more files please!'
    //     alert(errorMessage)
    //   })
    // },
    isFormValid() {
      if (
        this.model.name.en.length == 0 ||
        this.model.name.ar.length == 0 ||
        this.filesToSave.length == 0
      ) {
        this.errorMessage = 'menuPage.errors.emptyFields'
        return false
      }
      return true
    },
    doCancel() {
      this.clearDropzone()
      this.$router.push('/')
      // this.$router.back()
    },
    doReset() {
      this.model = formSchema.initialValues(this.record)
      this.clearDropzone()
    },
    async doSubmit() {
      if (!this.isFormValid()) {
        const ERROR = this.i18n(this.errorMessage)
        return Message.error(ERROR)
      }

      const { id, ...values } = formSchema.cast(this.model)
      // const { id, name } = formSchema.cast(this.model)
      if (this.isEditing) {
        await this.doUpdate({
          id,
          values,
          file: this.filesToSave[0]
        })
      } else {
        await this.doCreate({
          values,
          file: this.filesToSave[0]
        })
      }
    }
  }
})
</script>
